<template>
  <div class="about">
    <div class="wrap w contentWarp" v-if="$route.query.type==='1'">
      <div v-html="msg[1]"></div>
      <!-- <div class="wrap-top"> -->
      <!-- <div class="wrap-top-left">
          <h5>公司简介</h5>
          <i>COMPANY&nbsp;PROFILE</i>
          <div class="xian"></div>
          <span></span>
          <P>
            华巨臣致力于为企业搭建高品质商务交流展示平台的同时，
            以加强行业信息流通和促进区域经济合作为己任。
          </P>
          <span>
            成立迄今，华巨臣为深圳地区会展行业的发展作出了不懈努力
            与巨大贡献。华巨臣凭借深厚的会展行业背景、丰富的展览运
            作经验、完善的市场运营体系和的会展专业人才，成功举办了
            多个展会，不仅创下了行业展览会的新高，也赢得了参展企业的高度评价。
          </span>
        </div>
        <div class="wrap-top-middle"></div>
        <div class="wrap-top-right">
          <div class="box"></div>
          <img src="../assets/images/00.gif" alt />
        </div>
      </div>-->
      <!-- 公司历史 -->
      <!-- <div class="title w">
        <h5>公司历史</h5>
        <p>COMPANY&nbsp;PROFILE</p>
      </div>-->
      <!-- nav图片 -->
      <!-- <div class="nav w">
        <div class="nav-top">
          <img src="../assets/images/01.gif" alt />
        </div>

        <div class="nav-middle">
          <div class="xian1"></div>
          <div class="xian2"></div>
        </div>
        <div class="nav-bottom">
          <div class="nav-bottom-left">
            <i>
              公司主要经营, 华巨臣致力于为企业搭建高品质
              商务交流展示平台的同时，以加强行业信息流通和
              促进区域经济合作为己任。成立迄今，华巨臣为深
              圳地区会展行业的发展作出了不懈努力与巨大贡献。
            </i>
          </div>
          <div class="nav-bottom-right">
            <i>
              公司主要经营, 华巨臣致力于为企业搭建高品质
              商务交流展示平台的同时，以加强行业信息流通和
              促进区域经济合作为己任。成立迄今，华巨臣为深
              圳地区会展行业的发展作出了不懈努力与巨大贡献。
            </i>
          </div>
        </div>
      </div>-->
      <!-- 公司文化 -->
      <!-- <div class="culture w">
        <div class="culture-left">
          <img src="../assets/images/02.gif" alt />
        </div>
        <div class="culture-right">
          <h5>公司文化</h5>
          <i>COMPANY&nbsp;PROFILE</i>
          <div class="xian3"></div>
          <span></span>
          <P>
            华巨臣一直致力于为企业搭建
            商务交流展示平台，以为加强行
            业信息流通与加强区域经济合作为
            己任，为深圳地区的会展行业发展做出不懈的努力。
          </P>
          <span>
            华巨臣一直致力于为企业搭建商务
            交流展示平台，以为加强行业信息流通与
            加强区域经济合作为己任，为深圳地区的
            会展行业发展做出不懈的努力。
          </span>
        </div>
      </div>-->
    </div>
    <div class="wrap w" v-else-if="$route.query.type==='2'">
      <template v-if="msg[2].length">
        <div class="history" v-for="(item, i) in msg[2]" :key="i" @click="goAboutDet(item.id)">
          <el-image class="l imgLeftOver" :src="item.image" fit="contain" lazy></el-image>
          <div class="r">
            <h3 class="h_title">{{item.name}}</h3>
            <p class="time">展会时间：{{item.start_time}}——{{item.end_time}}</p>
            <p>展会简介：{{item.brief}}</p>
          </div>
        </div>
      </template>
      <empty v-else />
    </div>
  </div>
</template>

<script>
import { getGreviousExh, getCloudNoticeListData } from "@/api/index.js";
import empty from "@/components/emptyBox/index";
export default {
  name: "About",
  components: { empty },
  data() {
    return {
      // type: 1, // 1=关于我们; 2=往届展会
      msg: {
        1: {},
        2: [
          {
            id: 1,
            event_name:
              "重磅大咖齐聚鹏城，共话白茶新未来！重磅大咖齐聚鹏城，共话白茶新未来！重磅大咖齐聚鹏城，共话白茶新未来！重磅大咖齐聚鹏城，共话白茶新未来！",
            start_time: "2019/02/22",
            end_time: "2019/02-25",
            image: require("@/assets/images/04.gif"),
            brief:
              "展会简介：在2019中国（深圳）国际茶产业博览会期间举办的——首届云南白茶高峰论坛，将会以专业的角度，给你专业的回答。论坛由云南省农业科学院茶叶研究所主办，深圳华巨臣实业有限公司承办，得到了中国茶叶学会、天津茶叶学会的支持。"
          }
        ]
      },
      page: 1
    };
  },
  mounted() {
    getGreviousExh({
      page: this.page
    }).then(res => {
      if (res.code == "200") {
        this.msg[2] = res.data.data;
      }
    }),
      getCloudNoticeListData({
        key: "aboutUs"
      }).then(res => {
        if (res.code == "200") {
          this.msg[1] = res.data.value;
        }
      });
  },
  methods: {
    goAboutDet(id) {
      this.$router.push("/aboutDet?id=" + id);
    }
  }
};
</script>

<style scoped  lang="scss">
.contentWarp {
  margin-top: 60px;
  font-size: 14px;
  line-height: 30px;
  padding-bottom: 50px;
}
.imgLeftOver {
  width: 386px;
  height: 256px;
}
.wrap {
  //  height: 1846px;
  //  background: brown;

  .wrap-top {
    margin-top: 70px;
    height: 530px;
  }

  .wrap-top-left {
    margin-top: 98px;
    float: left;
    width: 540px;
    position: relative;
    // height:530px;
    // background: lemonchiffon;
    .xian {
      position: absolute;
      top: 105px;
      width: 80px;
      height: 1px;
      background: #0e9b32;
    }

    h5 {
      font-size: 26px;
      color: #3a3a3a;
    }
    i {
      margin-top: 20px;
      display: inline-block;
      font-size: 20px;
      color: #a3a3a3;
    }
    p {
      margin-top: 73px;
      color: #a2a2a2;
      line-height: 35px;
    }
    span {
      display: inline-block;
      margin-top: 40px;
      color: #a2a2a2;
      line-height: 35px;
    }
  }
  .wrap-top-middle {
    float: left;
    height: 530px;
    width: 130px;
    // background: lightcoral;
  }
  .wrap-top-right {
    float: left;
    height: 530px;
    width: 530px;

    // .box{
    //   // position: absolute;
    //   width: 500px;
    //   height: 500px;
    //   background: #F5F5F5;

    // }
  }
  .title {
    margin-top: 85px;
    height: 65px;
    // background: lightcyan;

    h5 {
      color: #3a3a3a;
      font-size: 26px;
      text-align: center;
    }

    p {
      display: block;
      font-size: 20px;
      color: #a3a3a3;
      margin-top: 20px;
      text-align: center;
    }
  }
  .nav {
    margin-top: 50px;
    height: 444px;
    // background: chartreuse;

    .nav-top {
      height: 300px;
      // background: chocolate;
    }
    .nav-middle {
      height: 54px;
      position: relative;
      .xian1 {
        width: 80px;
        height: 1px;
        background: #0e9b32;
        position: absolute;
        top: 30px;
      }
      .xian2 {
        width: 80px;
        height: 1px;
        background: #0e9b32;
        position: absolute;
        top: 30px;
        left: 620px;
      }

      // background: coral;
    }
    .nav-bottom {
      height: 90px;
      // position: relative;

      // .xian2{
      //    width: 40px;
      //    height: 1px;
      //    color: #0E9B32;
      //    position: absolute;
      // }
      // background: cornflowerblue;

      .nav-bottom-left {
        float: left;
        height: 90px;
        width: 580px;

        i {
          display: block;
          font-weight: 400;
          color: #b5b5b5;
          line-height: 30px;
        }
      }
      .nav-bottom-right {
        float: right;
        height: 90px;
        width: 580px;
        // background: darkblue;

        i {
          display: block;
          font-weight: 400;
          color: #b5b5b5;
          line-height: 30px;
        }
      }
    }
  }
  .culture {
    height: 400px;
    //  background: darkgoldenrod;
    margin-bottom: 100px;
    margin-top: 85px;

    .culture-left {
      float: left;
      height: 400px;
      width: 653px;
      //  background: darkgreen;
    }
    .culture-right {
      float: left;
      height: 400px;
      width: 547px;
      position: relative;
      //  background: darkorchid;
      .xian3 {
        width: 80px;
        height: 1px;
        background: #0e9b32;
        position: absolute;
        top: 160px;
      }

      h5 {
        font-size: 26px;
        color: #3a3a3a;
        margin-top: 50px;
      }

      i {
        margin-top: 10px;
        display: inline-block;
        font-size: 20px;
        color: #a3a3a3;
      }

      p {
        margin-top: 73px;
        color: #a2a2a2;
        line-height: 35px;
      }

      span {
        display: inline-block;
        margin-top: 40px;
        color: #a2a2a2;
        line-height: 35px;
      }
    }
  }

  .history {
    line-height: 1.8em;
    margin: 60px 0px 40px 0;
    display: flex;
    .l {
      margin-right: 56px;
      flex: none;
    }
    .r {
      color: #a2a2a2;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .h_title {
        margin-bottom: 21px;
        font-size: 22px;
        color: #3a3a3a;
      }
      .time {
        margin-bottom: 40px;
        color: #666;
      }
    }
  }
}
.swiper-container {
  height: 800px;
  width: 100%;

  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
      // background-color: #42b983;
      text-align: center;
      line-height: 500px;
      height: 100%;
      width: 100%;
      background: url(../assets/images/banner4.jpg) no-repeat center top;
      background-size: 1920px 500px;
    }
  }
}
</style>
